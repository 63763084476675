import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.node
  };

  static defaultProps = {
    children: null
  };

  componentDidUpdate(prevProps) {
    const { location: nextLocation } = this.props;
    const { location: currentLocation } = prevProps;

    if (
      nextLocation.pathname !== currentLocation.pathname
      || nextLocation.search !== currentLocation.search
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export const ScrollToTopRoute = withRouter(ScrollToTop);
