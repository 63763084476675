import React from 'react';
import PropTypes from 'prop-types';
import sanskritTranscoder from 'sanskrit-transcoder';
import { NavLink } from 'react-router-dom';

import { renderDictionaryElement } from '../util/render-html';

import '../style/dictionary-entry.scss';


export const RESULT_ENTRY_CLASS_NAME = 'dictionary-entry__result';
export const RESULT_ENTRYTITLE_CLASS_NAME = 'dictionary-entry__title';
export const DICTIONARY_ELEMENT_CLASS_NAME = 'dictionary-entry__element';
export const DICTIONARY_INTERLINK_CLASS_NAME = 'dictionary-entry__interlink';
export const DICTIONARY_WORDENTRY_CLASS_NAME = 'dictionary-entry__wordentry';

export class DictionaryEntry extends React.Component {
  static propTypes = {
    keyname: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    entries: PropTypes.array.isRequired,
    transcodeFrom: PropTypes.string.isRequired,
    transcodeTo: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  };

  shouldComponentUpdate(nextProps) {
    const {
      keyname,
      type,
      transcodeFrom,
      transcodeTo
    } = this.props;

    if (
      nextProps.keyname !== keyname
      || nextProps.type !== type
      || nextProps.transcodeFrom !== transcodeFrom
      || nextProps.transcodeTo !== transcodeTo
    ) {
      return true;
    }

    return false;
  }

  renderEntry(wordEntry, transcodeFrom, transcodeTo) {
    const { body, level } = wordEntry;
    const wordEntryClassName = `${DICTIONARY_WORDENTRY_CLASS_NAME} ${DICTIONARY_WORDENTRY_CLASS_NAME}--${level}`;
    const contents = renderDictionaryElement(body, (node, children) => {
      const { name, attribs } = node;
      const dictElementClassName = `${DICTIONARY_ELEMENT_CLASS_NAME} ${DICTIONARY_ELEMENT_CLASS_NAME}--${name}`;
      let processedChildren = children;

      if (attribs.slp1) {
        const { slp1 } = attribs;
        const linkTranscoded = sanskritTranscoder(slp1, 'slp1', transcodeFrom).replace(/[/\-–]/g, '');
        const route = `/${transcodeFrom}/${transcodeTo}/${linkTranscoded}`;

        processedChildren = (
          <NavLink
            className={DICTIONARY_INTERLINK_CLASS_NAME}
            to={route}
          >
            {sanskritTranscoder(slp1, 'slp1', transcodeTo)}
          </NavLink>
        );
      } else if (name === 's') {
        processedChildren = children.map((el) => {
          const slp1 = el && el.replace ? el.replace(/[/\-—√ °]/g, '') : '';
          const linkTranscoded = sanskritTranscoder(slp1, 'slp1', transcodeFrom);
          const route = `/${transcodeFrom}/${transcodeTo}/${linkTranscoded}`;
          const toTranscode = el && el.replace ? el.replace(/[/^]/g, '') : '';

          return (
            <NavLink
              className={DICTIONARY_INTERLINK_CLASS_NAME}
              to={route}
              key={Math.random()}
            >
              {sanskritTranscoder(toTranscode, 'slp1', transcodeTo)}
            </NavLink>
          );
        });
      }

      return (
        <span key={`${Math.random()}`} className={dictElementClassName}>{processedChildren}</span>
      );
    });

    return (
      <div key={`${Math.random()}`} className={wordEntryClassName}>
        {contents}
      </div>
    );
  }

  renderKeyTitle() {
    const { keyname, transcodeFrom, transcodeTo } = this.props;
    const linkTranscoded = sanskritTranscoder(keyname, 'slp1', transcodeFrom);
    const route = `/${transcodeFrom}/${transcodeTo}/${linkTranscoded}`;
    const fullTitle = sanskritTranscoder(keyname, 'slp1', transcodeTo);

    return (
      <h2 className={RESULT_ENTRYTITLE_CLASS_NAME} id={keyname}>
        <NavLink to={route}>
          <strong>{fullTitle}</strong>
        </NavLink>
      </h2>
    );
  }

  renderEntries() {
    const { entries, transcodeFrom, transcodeTo } = this.props;
    const entryItems = entries.map((wordEntry) => {
      return this.renderEntry(wordEntry, transcodeFrom, transcodeTo);
    });

    return entryItems;
  }

  render() {
    const { type, index, total } = this.props;
    const baseClassname = RESULT_ENTRY_CLASS_NAME;
    let className = `${baseClassname} ${baseClassname}--${type}`;

    if (index === 0) {
      className = `${className} ${baseClassname}--first`;
    }

    if (index === total - 1) {
      className = `${className} ${baseClassname}--last`;
    }

    return (
      <div className={className}>
        {this.renderKeyTitle()}
        {this.renderEntries()}
      </div>
    );
  }
}
