import React from 'react';
import { Redirect, Router, Route, Switch } from 'react-router-dom';

import { history } from './util/history';

import { ScrollToTopRoute } from './routes/route-scroll-to-top';
import { HomeRoute } from './routes/route-home';


export default class App extends React.Component {
  render() {
    return (
      <Router history={history}>
        <ScrollToTopRoute>
          <Switch>
            <Route exact path="/">
              <Redirect to="/hk/deva/" />
            </Route>

            <Route
              exact
              path="/:transcodeFrom"
              render={({ match }) => {
                const { params } = match;
                const { transcodeFrom } = params;

                return (<Redirect to={`/${transcodeFrom}/deva`} />);
              }}
            />

            <Route
              path="/:transcodeFrom/:transcodeTo/:searchString?"
              render={({ match }) => {
                return (<HomeRoute match={match} />);
              }}
            />
          </Switch>
        </ScrollToTopRoute>
      </Router>
    );
  }
}
