import React from 'react';
import PropTypes from 'prop-types';

import '../style/content-list.scss';


export const CLASS_NAME = 'content-list';

export class ContentList extends React.PureComponent {
    static propTypes = {
      children: PropTypes.node
    };

    static defaultProps = {
      children: null
    };

    render() {
      const { children } = this.props;
      return (
        <ul className={CLASS_NAME}>
          {children}
        </ul>
      );
    }
}
