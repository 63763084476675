import React from 'react';
import PropTypes from 'prop-types';

import '../style/content-body.scss';


export const CLASS_NAME = 'content-body';

export class ContentBody extends React.PureComponent {
    static propTypes = {
      children: PropTypes.node
    };

    static defaultProps = {
      children: null
    };

    render() {
      const { children } = this.props;
      return (
        <p className={CLASS_NAME}>
          {children}
        </p>
      );
    }
}
