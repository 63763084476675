import React from 'react';

import '../style/logo.scss';


export const CLASS_NAME = 'logo';

export default function Logo() {
  return (
    <svg className={CLASS_NAME} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15ZM22.4894 15.2719V7.51056L14.728 7.51056L14.728 10.5106L17.3681 10.5106L12.8524 15.0262H7.36609L7.36609 18.0262H9.85242L7.43042 20.4482L9.55174 22.5696L11.9737 20.1476V22.6339H14.9737L14.9737 17.1476L19.4894 12.6319V15.2719H22.4894Z" fill="#F8326B" />
    </svg>
  );
}
