import React from 'react';
import PropTypes from 'prop-types';

import indexRetriever from '../util/index-retriever';
import { SearchAutocomplete } from './search-autocomplete';
import { Tool } from './tool';
import { ClearIcon } from './icons/clear';

import '../style/search.scss';


export const CLASS_NAME = 'nav-bar-search';
export const INPUT_CLASS_NAME = 'nav-bar-search__input';
export const ACTIONS_CLASS_NAME = 'nav-bar-search__actions';

const flattenMatches = (matches = {}) => {
  const {
    exactMatch = null,
    prefixMatches = [],
    relatedMatches = []
  } = matches;

  let matchesFlat = [];

  if (exactMatch) {
    matchesFlat.push({
      type: 'exact',
      item: exactMatch
    });
  }

  if (prefixMatches.length) {
    const items = prefixMatches.map((item) => { return { type: 'prefix', item }; });
    matchesFlat = matchesFlat.concat(items);
  }

  if (relatedMatches.length) {
    const items = relatedMatches.map((item) => { return { type: 'related', item }; });
    matchesFlat = matchesFlat.concat(items);
  }

  return matchesFlat;
};

export class Search extends React.PureComponent {
    static propTypes = {
      windowConfig: PropTypes.object.isRequired,
      currentValue: PropTypes.string,
      onSubmit: PropTypes.func,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onClear: PropTypes.func,
      onMatch: PropTypes.func,
      autoStart: PropTypes.bool,
      renderElement: PropTypes.func,
      preProcessor: PropTypes.func,
      optionComponent: PropTypes.node
    };

    static defaultProps = {
      onChange: () => {},
      onBlur: () => {},
      onSubmit: () => {},
      onMatch: () => {},
      onClear: () => {},
      renderElement: () => { return null; },
      currentValue: undefined,
      autoStart: false,
      preProcessor: (val) => { return val; },
      optionComponent: null
    };


    // Component lifecycle

    constructor(props) {
      super(props);

      const defaultSearch = props.currentValue || '';

      this.state = {
        fullSearchString: defaultSearch,
        autoCompleteMatches: null
      };

      indexRetriever
        .setKey('key')
        .setPreprocesor(props.preProcessor)
        .setSearch(defaultSearch)
        .on('matches', (matches) => {
          const autoCompleteMatches = matches;
          const { fullSearchString } = this.state;

          this.setState({ autoCompleteMatches }, () => {
            const { onMatch } = this.props;
            onMatch(fullSearchString, autoCompleteMatches);
          });
        });
    }

    componentDidMount() {
      const { autoStart } = this.props;

      if (autoStart) {
        indexRetriever.start({ immediate: true });
      }
    }

    componentWillReceiveProps(nextProps) {
      const { currentValue, autoStart, preProcessor } = this.props;

      if (nextProps.preProcessor !== preProcessor) {
        indexRetriever.setPreprocesor(nextProps.preProcessor);
      }

      if (nextProps.currentValue !== currentValue) {
        const nextSearch = nextProps.currentValue || '';

        this.setState({
          fullSearchString: nextSearch
        });

        indexRetriever.setSearch(nextSearch);

        if (autoStart) {
          indexRetriever.start({ immediate: true });
        }
      }
    }


    // Event handlers

    handleClear = (event) => {
      const { onClear } = this.props;
      event.preventDefault();

      this.setState({
        fullSearchString: '',
        autoCompleteMatches: null
      }, () => {
        this.searchInputElement.focus();
      });

      onClear();
    };

    handleSubmit = (currentSearch) => {
      const { onSubmit } = this.props;
      onSubmit(currentSearch);
    };

    handleChange = (event) => {
      const { onChange, onClear } = this.props;
      const { value } = event.target;
      const fullSearchString = value.replace(/[^[A-Za-zÀ-ÖØ-öø-ÿ]]|[0-9]/g, '');

      this.setState({
        fullSearchString
      }, () => {
        indexRetriever
          .setSearch(fullSearchString)
          .start({ immediate: true });

        onChange(fullSearchString);

        if (!fullSearchString) {
          onClear();
        }
      });
    };

    handleFocus = () => {};

    handleBlur = () => {
      const { fullSearchString } = this.state;
      const { onBlur } = this.props;

      onBlur(fullSearchString);
      this.handleAutocompleteExit();
    };

    handleKeydown = (event) => {
      const { key, keyCode } = event;
      const { fullSearchString } = this.state;
      const list = this.autoCompleteElement;

      if (key === 'Enter') {
        this.handleSubmit(fullSearchString);
        event.preventDefault();
      } else if ((keyCode === 40 || keyCode === 38) && list) {
        // Down Arrow Key
        if (keyCode === 40) {
          if (event.metaKey) {
            list.selectLast();
          } else {
            list.selectNext();
          }
        } else if (event.metaKey) {
          list.selectFirst();
        } else {
          list.selectPrevious();
        }

        event.preventDefault();
      } else if (keyCode === 27) {
        // Escape
        this.handleAutocompleteExit();
      }
    };


    // Autocomplete handlers

    handleAutocompleteExit = () => {};

    handleAutocompleteSelect = () => {};

    handleAutocompleteSubmit = (value) => {
      if (value) {
        this.handleSubmit(value);
      }
    };


    // Render Methods

    renderAutocomplete() {
      const { autoCompleteMatches, fullSearchString } = this.state;
      const { renderElement, windowConfig } = this.props;
      const flatMatches = flattenMatches(autoCompleteMatches || undefined);

      if (fullSearchString && flatMatches.length) {
        return (
          <SearchAutocomplete
            windowConfig={windowConfig}
            ref={(c) => { this.autoCompleteElement = c; }}
            matches={flatMatches}
            onSubmit={this.handleAutocompleteSubmit}
            onSelect={this.handleAutocompleteSelect}
            onExit={this.handleAutocompleteExit}
            renderElement={renderElement}
          />
        );
      }

      return null;
    }

    renderClear() {
      const { fullSearchString } = this.state;

      if (fullSearchString) {
        return (
          <Tool onClick={this.handleClear}><ClearIcon /></Tool>
        );
      }

      return null;
    }

    renderOptions() {
      const { optionComponent } = this.props;

      return (optionComponent);
    }

    renderActions() {
      return (
        <div className={ACTIONS_CLASS_NAME}>
          {this.renderClear()}
          {this.renderOptions()}
        </div>
      );
    }

    render() {
      const { fullSearchString } = this.state;

      return (
        <div className={CLASS_NAME}>
          <input
            ref={(c) => { this.searchInputElement = c; }}
            className={INPUT_CLASS_NAME}
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder="search for a word"
            value={fullSearchString}
            onKeyDown={this.handleKeydown}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
          {this.renderActions()}
          {this.renderAutocomplete()}
        </div>
      );
    }
}
