import superagent from 'superagent';
import superagentCache from 'superagent-cache';

superagentCache(superagent);

export default {
  // Autocompletion

  getAutocomplete(searchString = '$_____') {
    const url = `/dict-index/${encodeURIComponent(searchString)}.json`;

    return superagent
      .get(url)
      .type('json')
      .withCredentials(false);
  }
};
