import queryString from 'query-string';
import { history } from './history';


class URLTool {
  getQuery() {
    const location = history ? history.location : window.location;
    const query = queryString.parse(location.search);

    return query;
  }

  buildSearchString(query) {
    const search = query ? `?${queryString.stringify(query)}` : '';
    return search;
  }

  updateQuery(obj) {
    const { search: oldSearch } = history.location;
    const searchParsed = queryString.parse(oldSearch);

    Object.keys(obj).forEach((key) => {
      const entry = obj[key];

      Object.assign(searchParsed, {
        [key]: !entry ? undefined : entry
      });
    });

    const searchString = `${queryString.stringify(searchParsed)}`;
    const search = searchString ? `?${queryString.stringify(searchParsed)}` : '';

    history.push({
      pathname: history.location.pathname,
      search
    });
  }

  replacePathElement(from, to) {
    const {
      search,
      pathname: oldLocation
    } = history.location;

    const newLocation = oldLocation.replace(new RegExp(from, 'ig'), to);

    if (newLocation !== oldLocation) {
      history.push({
        pathname: newLocation,
        search
      });
    }
  }

  replaceTo(path) {
    const { pathname: currentPathname } = history.location;

    if (typeof path === 'string') {
      if (path !== currentPathname) {
        history.replace({ pathname: path });
      }
    } else {
      const { pathname, search } = path;

      if (currentPathname !== pathname) {
        history.replace({ pathname, search });
      }
    }
  }

  navigateTo(path) {
    const { pathname: currentPathname } = history.location;

    if (typeof path === 'string') {
      if (path !== currentPathname) {
        history.push({ pathname: path });
      }
    } else {
      const { pathname, search } = path;

      if (currentPathname !== pathname) {
        history.push({ pathname, search });
      }
    }
  }

  leaveTo(url) {
    window.location.href = url;
  }

  getCurrentPath() {
    const { pathname, search } = history.location;
    const path = search ? `${pathname}${search}` : pathname;

    return path;
  }
}


export const urlTool = new URLTool();
export default urlTool;
