import React from 'react';
import PropTypes from 'prop-types';

import '../style/layout.scss';


export const CLASS_NAME = 'layout';

export class Layout extends React.PureComponent {
    static propTypes = {
      children: PropTypes.node,
      type: PropTypes.oneOf(['centered', 'normal'])
    };

    static defaultProps = {
      children: null,
      type: 'normal'
    };

    render() {
      const { children, type } = this.props;
      const className = `${CLASS_NAME} ${CLASS_NAME}--${type}`;

      return (
        <div className={className}>
          {children}
        </div>
      );
    }
}
