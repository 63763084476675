import React from 'react';
import PropTypes from 'prop-types';

import { Selector } from './selector';
import '../style/transcoding-selectors.scss';


const options = ['hk', 'slp1', 'itrans', 'wx', 'roman', 'deva'];

export const CLASS_NAME = 'transcoding-selectors';
export const LABEL_CLASS_NAME = 'transcoding-selectors__label';

export class TranscodingSelectors extends React.PureComponent {
  static propTypes = {
    onToChange: PropTypes.func.isRequired,
    onFromChange: PropTypes.func.isRequired,
    to: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    isCollapsed: PropTypes.bool
  };

  static defaultProps = {
    isCollapsed: false
  };

  render() {
    const {
      onToChange,
      onFromChange,
      to,
      from,
      isCollapsed
    } = this.props;

    let className = CLASS_NAME;
    if (isCollapsed) {
      className = `${className} ${className}--collapsed`;
    }

    return (
      <div className={className} hidden={isCollapsed} aria-hidden={isCollapsed}>
        <div className={`${LABEL_CLASS_NAME} ${LABEL_CLASS_NAME}--full-length`}>Transcode from</div>
        <div className={`${LABEL_CLASS_NAME} ${LABEL_CLASS_NAME}--short`}>From</div>
        <Selector value={from} options={options} onChange={onFromChange} />
        <div className={LABEL_CLASS_NAME}>to</div>
        <Selector value={to} options={options} onChange={onToChange} />
      </div>
    );
  }
}
