import { EventEmitter } from 'events';

import requests from './requests';

class IndexRetriever extends EventEmitter {
  constructor() {
    super();

    this.ignoreCase = false;
    this.indexKey = 'key';

    this.currentSearch = '';
    this.resultsList = [];
    this.resultSubstrings = {};
    this.emission = () => {};
    this.emissionTimeout = null;

    this.preProcessor = (searchString) => { return searchString; };
  }


  // AutoComplete Provider Methods
  setKey(key) {
    this.indexKey = key;

    return this;
  }

  setSearch(newSearchString) {
    this.currentSearch = this.preProcessor(newSearchString);

    return this;
  }

  setIgnoreCase(doesIgnore) {
    this.ignoreCase = doesIgnore;

    return this;
  }

  setPreprocesor(preProcessor) {
    this.preProcessor = preProcessor;

    return this;
  }

  start(config = {}) {
    const { immediate = true } = config;
    this.iterateDepthSearch({ depth: 1, immediate });

    return this;
  }


  // Internal Methods

  iterateDepthSearch({ depth = 1, immediate }) {
    const {
      currentSearch,
      resultSubstrings,
      ignoreCase
    } = this;


    // If no search, emit no matches
    if (!currentSearch) {
      return this.emitMatches({ matches: null, immediate });
    }


    const nextDepth = depth + 1;
    let searchSubstring = currentSearch.slice(0, depth);
    let nextSearchSubstring = currentSearch.slice(0, nextDepth);

    if (ignoreCase === true) {
      searchSubstring = searchSubstring.toLowerCase();
      nextSearchSubstring = nextSearchSubstring.toLowerCase();
    }


    const isMore = searchSubstring.length < currentSearch.length;
    const nextDepthResults = resultSubstrings[nextSearchSubstring];


    // If there is a further along substring search
    // don't bother with the current substring
    if (isMore === true && nextDepthResults !== undefined) {
      return this.iterateDepthSearch({ depth: nextDepth, immediate });
    }

    // Otherwise see if there are results for the current depth
    const currentDepthResults = resultSubstrings[searchSubstring];

    // If the current substring hasn't been searched yet
    if (currentDepthResults === undefined) {
      resultSubstrings[searchSubstring] = requests
        .getAutocomplete(searchSubstring)
        .then((response) => {
          const { body } = response;
          const index = body || {
            results: [],
            terminal: true
          };
          const {
            results,
            terminal: isTerminal
          } = index;

          this.addNewResults(results, currentSearch);

          if (isMore === true && isTerminal !== true) {
            return Promise.resolve(this.iterateDepthSearch({ depth: nextDepth, immediate }));
          }

          this.filterResults({ immediate });

          return Promise.resolve(isTerminal);
        })
        .catch((err) => {
          if (err.status === 404) {
            this.filterResults({ immediate });
            return Promise.resolve(true);
          }

          throw err;
        });

      return null;
    }

    // otherwise wait for the same promise to resolve then move on
    return Promise
      .resolve(currentDepthResults)
      .then((isTerminal) => {
        if (isMore === true && isTerminal !== true) {
          return this.iterateDepthSearch({ depth: nextDepth, immediate });
        }

        this.filterResults({ immediate });

        return isTerminal;
      })
      .catch(() => {
        console.error('Something weird happened');
      });
  }

  filterResults({ immediate }) {
    const {
      currentSearch,
      indexKey,
      ignoreCase
    } = this;

    const matches = {
      exactMatch: null,
      prefixMatches: [],
      relatedMatches: []
    };

    let searchString = currentSearch;
    let filter = (row) => {
      const rowIndexKey = row[indexKey];
      return rowIndexKey.startsWith(searchString) && rowIndexKey !== searchString;
    };

    if (ignoreCase === true) {
      searchString = searchString.toLowerCase();

      filter = (row) => {
        const rowIndexKey = row[indexKey].toLowerCase();
        return rowIndexKey.startsWith(searchString) && rowIndexKey !== searchString;
      };
    }

    if (!currentSearch) {
      this.emitMatches({ matches, immediate });
    } else {
      const prefixMatches = this.resultsList.filter(filter);
      const exactMatch = this.resultsList.find((row) => {
        if (ignoreCase === true) {
          return row[indexKey].toLowerCase() === searchString.toLowerCase();
        }

        return row[indexKey] === searchString;
      });
      let relatedMatches = [];

      if (!prefixMatches.length && !exactMatch) {
        while (searchString.length && !relatedMatches.length) {
          searchString = searchString.slice(0, -1);
          relatedMatches = this.resultsList.filter(filter);
        }
      }

      Object.assign(matches, {
        prefixMatches,
        exactMatch,
        relatedMatches
      });

      this.emitMatches({ matches, immediate });
    }
  }

  addNewResults(results) {
    this.resultsList = this.resultsList.concat(results);
  }

  emitMatches({ matches, immediate }) {
    if (this.emissionTimeout) {
      clearTimeout(this.emissionTimeout);
      this.emissionTimeout = null;
    }

    if (immediate === true) {
      this.emit('matches', matches);
    } else {
      this.emissionTimeout = setTimeout(() => {
        this.emit('matches', matches);
      }, 250);
    }
  }
}

export default new IndexRetriever();
