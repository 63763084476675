import React from 'react';
import PropTypes from 'prop-types';
import {
  CellMeasurer,
  CellMeasurerCache,
  List,
  AutoSizer
} from 'react-virtualized';

import '../style/search-autocomplete.scss';


export const MAX_RESULTS = 1000;
export const CLASS_NAME = 'nav-bar-search-autocomplete';

const cache = new CellMeasurerCache({
  defaultHeight: 100,
  fixedWidth: true
});

export class SearchAutocomplete extends React.Component {
  static propTypes = {
    windowConfig: PropTypes.object.isRequired,
    matches: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onExit: PropTypes.func.isRequired,
    renderElement: PropTypes.func.isRequired
  };

  static defaultProps = {
    matches: []
  };


  // Component lifecycle

  constructor(props) {
    super(props);

    const { matches } = props;

    this.state = {
      selectedString: matches[0].key
    };

    cache.clearAll();

    this.autoCompleteList = [];
    this.searchedSubstrings = {};
    this.selectedIndex = 0;

    this.handleSelect = this.handleSelect.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { matches } = this.props;

    if (nextProps.matches !== matches) {
      cache.clearAll();
      this.list.scrollToRow(0);
      this.selectedIndex = 0;
      this.setState({
        selectedString: nextProps.matches[0].key
      });
    }
  }


  // Event handlers

  handleKeydown(event) {
    const { selectedString } = this.state;
    const { onSubmit, onExit } = this.props;
    const { key, keyCode } = event;

    if (key === 'Enter') {
      onSubmit(selectedString);
    } else if (keyCode === 27) {
      onExit();
    }
  }

  handleSelect(event) {
    const { onSelect } = this.props;
    const { value } = event.target;

    this.setState({
      selectedString: value
    });

    onSelect(value);
  }

  handleClick(match) {
    const { onSubmit } = this.props;
    onSubmit(match);
  }


  // Selection Management

  shiftSelection({ shiftAmount, to }) {
    const { onSelect, matches } = this.props;
    const { selectedIndex } = this;
    const max = matches.length > MAX_RESULTS ? MAX_RESULTS - 1 : matches.length - 1;
    let newSelectedIndex;

    if (shiftAmount !== undefined) {
      newSelectedIndex = selectedIndex + shiftAmount;
      if (newSelectedIndex > max) {
        newSelectedIndex = 0;
      } else if (newSelectedIndex < 0) {
        newSelectedIndex = max;
      }
    } else if (to !== undefined) {
      newSelectedIndex = to === 'last' ? max : 0;
    }

    const newSelection = matches[newSelectedIndex].key;
    this.selectedIndex = newSelectedIndex;
    onSelect(newSelection);
  }

  selectNext() {
    this.shiftSelection({ shiftAmount: 1 });
  }

  selectPrevious() {
    this.shiftSelection({ shiftAmount: -1 });
  }

  selectFirst() {
    this.shiftSelection({ to: 'first' });
  }

  selectLast() {
    this.shiftSelection({ to: 'last' });
  }


  // Render Methods

  rowRenderer({ index, key, parent, style }) {
    const { matches, renderElement } = this.props;
    const { type, item } = matches[index];

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild }) => {
          const content = renderElement({ type, item, index, total: matches.length });

          return (
            <div ref={registerChild} style={style}>
              {content}
            </div>
          );
        }}
      </CellMeasurer>
    );
  }

  render() {
    const { matches, windowConfig } = this.props;
    const numMatches = matches.length;
    const { height, isScrolling, registerChild, scrollTop } = windowConfig;

    if (numMatches < 1) {
      return null;
    }

    return (
      <div ref={registerChild} className={CLASS_NAME}>
        <AutoSizer disableHeight>
          {({ width }) => {
            return (
              <List
                style={{ outline: 'none' }}
                tabIndex={-1}
                autoHeight
                scrollTop={scrollTop}
                ref={(c) => { this.list = c; }}
                rowCount={numMatches}
                width={width}
                isScrolling={isScrolling}
                overscanRowCount={4}
                scrollToAlignment="start"
                height={height}
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                rowRenderer={(config) => { return this.rowRenderer(config); }}
              />
            );
          }}
        </AutoSizer>
      </div>
    );
  }
}
