import React from 'react';
import PropTypes from 'prop-types';

import '../style/tool.scss';


export const CLASS_NAME = 'tool';

export class Tool extends React.PureComponent {
    static propTypes = {
      children: PropTypes.node,
      onClick: PropTypes.func
    };

    static defaultProps = {
      children: null,
      onClick: 'normal'
    };

    render() {
      const { children, onClick } = this.props;

      return (
        <button className={CLASS_NAME} onClick={onClick} type="button">
          {children}
        </button>
      );
    }
}
