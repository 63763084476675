import React from 'react';
import PropTypes from 'prop-types';

import '../style/content-page-title.scss';


export const CLASS_NAME = 'content-page-title';

export class ContentPageTitle extends React.PureComponent {
    static propTypes = {
      children: PropTypes.node
    };

    static defaultProps = {
      children: null
    };

    render() {
      const { children } = this.props;
      return (
        <h1 className={CLASS_NAME}>
          {children}
        </h1>
      );
    }
}
